import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer,
} from '@paypal/react-paypal-js';

import api from '../../services/apiCalls.service';

const style = {
	layout: 'vertical',
	color: 'black',
	height: 46,
};

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, cartData, orderId }) => {
	const [{ isPending }] = usePayPalScriptReducer();
	const navigate = useNavigate();

	async function createOrder(data, actions) {
		return actions.order.create({
			purchase_units: [{ amount: { value: cartData.cart.total.toFixed(2) } }],
			custom_id: orderId,
		});
	}

	async function onApprove(data) {
		const success = await api.captureOrder(orderId, data);

		setTimeout(() => {
			navigate('/order-granted');
		}, 1e3);

		return success;
	}

	return (
		<>
			{((showSpinner && isPending) || !orderId) && <div className='spinner' />}
			<PayPalButtons
				style={style}
				disabled={false}
				forceReRender={[style]}
				fundingSource={'paypal'}
				createOrder={createOrder}
				onApprove={onApprove}
			/>
		</>
	);
};

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4.6rem;
	cursor: pointer;
	border: 1px solid transparent;
	transition: all 0.2s ease;

	& > span {
		font-weight: 450;
		font-size: ${props => props.theme.fontsm};
		line-height: 1.8rem;
		color: #adadad;
		transition: all 0.2s ease;
	}
	&:hover {
		background: transparent;
		border: 1px solid ${props => props.theme.colorMain};
		& > span {
			color: ${props => props.theme.colorMain};
		}
	}
	@media screen and (max-width: 1100px) {
		height: 6rem;
		& > span {
			font-size: 1.8rem;
			line-height: 2.2rem;
		}
	}
`;

function FondyPaymentButton({ cartData }) {
	const [orderId, setOrderId] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [but_disabled, setBut_disabled] = useState(false);

	async function create_Invoice(body) {
		const token = 'mdFzm8rIJfesYZlwF_WVGdg';
		// mdFzm8rIJfesYZlwF_WVGdg  // рабочий
		// uudVU6_4JSxDxUYUg_Vc__vjN71KmEDbg-HNS-v6nMXk  //тестовый
	
		const responseMB = await fetch('https://api.monobank.ua/api/merchant/invoice/create', {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
			'x-token': token,
		  },
		  body: JSON.stringify(body),
		})
	
		return await responseMB.json();
	
		//window.location.href = respMsg.pageUrl
	  }

	async function msg_to_tg(body) {
		const responseTG = await fetch('https://nerv-api.vercel.app/api/telegram', {
			method: 'POST',
			mode: 'no-cors',
			headers: {
			'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})

		return await responseTG;
	}

	async function get_course() {
		
		const responseCourse = await fetch('https://nerv-api.vercel.app/api/get_course', {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json'
			},
		  })
		return await responseCourse.json();
	}
	async function save_new_course() {
		const responseCourse = await fetch('https://nerv-api.vercel.app/api/save_new_course', {
			method: 'GET',
			mode: 'no-cors',
			headers: {
			'Content-Type': 'application/json',
			},
		})

		return await responseCourse.json();
	}

	const handlePayButtonClick = React.useCallback(async (event) => {
		event.preventDefault();
		setBut_disabled(true);

		setErrorMessage(false);

		const savingResult = await api.saveShoppingCart(cartData);

		if (savingResult.error) {
			setErrorMessage('Failed to generate payment link :( Please try again');
			return;
		}

		if (!savingResult.checkoutUrl & false) {
			setErrorMessage(
				'Failed to generate payment link for Fondy :(. Please try again'
			);
			return;
		}

		setOrderId(savingResult.orderId);

		const course = await get_course();
		//console.log(`курс =  ${course}`);
		console.log(course);
		
		console.log(cartData);
		console.log(`заказ ${savingResult.orderId}`);
		const total_cost = Math.round(cartData.cart.total*100*course);
		let mass = [];
		let summ_rpice = 0;
		cartData.cart.products.forEach((el) => {
			summ_rpice+=el.price;
			let size_full = "";
			let size_short = "";
			if (el.description.sizeFull != "") {size_full = `/ ${el.description.sizeFull}`}
			if (el.description.sizeShort != "") {size_short = `(${el.description.sizeShort})`}
			mass.push({
			"name": ` ${el.name}  / ${el.model} / ${el.description.color} ${size_full}${size_short} / ${el.desc}`,
			"qty": el.quantity,
			"sum": Math.round(el.price*100*course),
			"icon": el.imgGear,
			"code": el.specificId,
			"unit": "шт.",
			"barcode": "",
			"header": el.desc,
			"footer": `${el.model} / ${el.description.color} / ${el.description.sizeFull} `,
			})
		});
		// "code": el._id,

		if (summ_rpice < cartData.cart.total) {
			const shipping_price = Math.round((cartData.cart.total - summ_rpice)*100*course);
			mass.push({
				"name": ` SHIPPING`,
				"qty": 1,
				"sum": shipping_price,
				"code": "553377",
				"unit": " ",
				"barcode": "",
				"footer": "Delivery of the remaining goods in the order",
				});
			//"icon": "https://i.postimg.cc/tJ9SgbkH/2da3b792d602f4254ca9ff29827600ed.webp",

		}

		const body_to_req = {
			amount: total_cost,
			ccy: 980,
			merchantPaymInfo: {
			  "reference": `${savingResult.orderId}`,
			  "destination": "Оплата nervcorporation.com",
			  "comment": `Оплата заказа ${savingResult.orderId}`,
			  "basketOrder": mass,
			  "customerEmails": [cartData.contactInfo.email]
			},
			redirectUrl: "https://nervcorporation.com/",
			webHookUrl: "https://nerv-api.vercel.app/api/take_message",
			validity: 3600,
		   };

		//https://nervcorporation.com/api/shopping_cart/order_callback
		console.log(body_to_req);
		const res_create_invoice = await create_Invoice(body_to_req);

		const body_to_req_tg = {
			status: "my_create",
			amount: cartData.cart.total,
			ccy: 840,
			merchantPaymInfo: {
			  "reference": `${savingResult.orderId}`,
			  "destination": "Оплата nervcorporation.com",
			  "comment": `Оплата заказа ${savingResult.orderId}`,
			  "basketOrder": mass
			},
			
		   };
		msg_to_tg(body_to_req_tg);
		save_new_course();
		console.log(`link ${res_create_invoice.pageUrl}`);
		//setBut_disabled(false);
		window.location.href = res_create_invoice.pageUrl;
	}, []);

	return (
		<React.Fragment>
			{errorMessage && <div style={{ fontSize: 'red' }}>{errorMessage}</div>}

			<Button onClick={handlePayButtonClick} disabled={but_disabled}>
				<span>PAY VIA MONOBANK </span>
			</Button>

			{/* <div style={{ height: '10px' }}></div>

			<div style={{ maxWidth: '750px', width: '100%', margin: 'auto' }}>
				<PayPalScriptProvider
					options={{ clientId: 'test', components: 'buttons', currency: 'USD' }}
				>
					<ButtonWrapper
						cartData={cartData}
						orderId={orderId}
						showSpinner={false}
					/>
				</PayPalScriptProvider>
			</div> */}
		</React.Fragment>
	);
}

export default FondyPaymentButton;


