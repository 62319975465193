import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
	name: 'cart',
	initialState: {
		products: [],
		quantity: 0,
		subtotal: 0,
		total: 0,
		location: '',
	},

	reducers: {
		addProduct: (state, action) => {
			const newItem = action.payload;
			const existingItem = state.products.find(
				item =>
					item._id === newItem._id && item.specificId === newItem.specificId
			);
			if (!existingItem) {
				state.products.push(newItem);
				state.quantity += 1;
				state.subtotal += newItem.price * newItem.quantity;
				if (state.subtotal > 200) {
					state.total = state.subtotal;
					return;
				}
				state.total += newItem.price * newItem.quantity;
			}
		},
		deleteProduct: (state, action) => {
			state.quantity -= 1;
			state.products = state.products.filter(
				item => item.specificId !== action.payload.specificId
			);
			state.subtotal -= action.payload.price * action.payload.quantity;
			if (
				state.subtotal < 200 &&
				state.location !== '' &&
				state.location.toLowerCase() !== 'ukraine'
			) {
				state.total = state.subtotal + 35;
				return;
			}
			state.total -= action.payload.price * action.payload.quantity;
		},
		incrementQuantity: (state, action) => {
			const existingProduct = state.products.find(
				product => product.specificId === action.payload.specificId
			);

			existingProduct.quantity += 1;
			state.subtotal += existingProduct.price;
			if (state.subtotal > 200) {
				state.total = state.subtotal;
				return;
			}
			state.total += existingProduct.price;
		},
		decrementQuantity: (state, action) => {
			const existingProduct = state.products.find(
				product => product.specificId === action.payload.specificId
			);
			existingProduct.quantity -= 1;
			state.subtotal -= existingProduct.price;

			if (
				state.subtotal < 200 &&
				state.location !== '' &&
				state.location.toLowerCase() !== 'ukraine'
			) {
				state.total = state.subtotal + 35;
				return;
			}
			state.total -= existingProduct.price;
		},
		setLocation: (state, action) => {
			state.location = action.payload;
		},
		addShippingPrice: (state, action) => {
			if (action.payload.toLowerCase() === state.location.toLocaleLowerCase()) {
				return;
			}

			if (
				state.subtotal < 200 &&
				action.payload.toLowerCase() === 'ukraine' &&
				state.location === ''
			) {
				state.location = action.payload;
				return;
			}

			if (
				state.subtotal <= 235 &&
				action.payload.toLowerCase() === 'ukraine' &&
				state.location !== ''
			) {
				state.total -= 35;
				state.location = action.payload;
				return;
			}

			if (state.subtotal < 200) {
				if (
					state.location === '' &&
					action.payload.toLowerCase() !== 'ukraine'
				) {
					state.total += 35;
					state.location = action.payload;
					return;
				}

				if (
					state.location.length > 0 &&
					action.payload.toLowerCase() === 'ukraine'
				) {
					state.total -= 35;
					state.location = action.payload;
					return;
				}

				if (
					state.location.length === 0 &&
					action.payload.toLowerCase() === 'ukraine'
				) {
					state.location = action.payload;
					return;
				}

				if (
					action.payload.toLowerCase() !== 'ukraine' &&
					state.location.toLowerCase() === 'ukraine'
				) {
					state.total += 35;
					state.location = action.payload;
					return;
				}
			}

			return;
		},
		deleteShippingPrice: (state, action) => {
			state.total -= 35;
		},
	},
});

export const {
	addProduct,
	deleteProduct,
	incrementQuantity,
	decrementQuantity,
	setLocation,
	addShippingPrice,
	deleteShippingPrice,
} = cartSlice.actions;
export default cartSlice.reducer;
